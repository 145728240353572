<template>
	<div>
		<daily-vip-filters
			:username-filter="usernameFilter"
			:product-filter.sync="productFilter"
			:product-options="productOptions"
			:status-filter.sync="statusFilter"
			:fromdate-filter.sync="fromdateFilter"
            :currency-filter.sync="currencyFilter"
            :currencyOptions="currencyOptions"
			:todate-filter.sync="todateFilter"
			:status-options="statusOptions"
		/>
        <b-alert
            variant="primary"
            show
            dismissible
        >
            <div class="alert-body">
                Datas below is demo
            </div>
        </b-alert>
		<b-card
			no-body
		>
			<b-table
				ref="refDailyVipTable"
				class="position-relative table-white-space mb-0"
				:items="dailyVipLists"
				responsive
				:fields="tableColumns"
				primary-key="index"
				show-empty
				empty-text="No matching records found"
			>
				<template #cell(index)="data">
					{{ perPage * (currentPage - 1) + data.index + 1 }}
				</template>

                <template #cell(eligible_stake_amount)="data">
                    {{ numberFormat(data.item.eligible_stake_amount) }}
                </template>

				<template #cell(status)="data">
					<div :class="`text-capitalize align-items-center d-flex`">
						<span :class="`dot bg-${resolveStatus(data.item.status).variant}`" />{{ resolveStatus(data.item.status).label }}
					</div>
				</template>

			</b-table>
			<div class="p-2">
				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
					</b-col>
					<!-- Pagination -->
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>
						<b-pagination
							v-model="currentPage"
							:total-rows="totalDailyVip"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
		<!-- Add Bonus Modal -->
		<!-- <add-bonus-modal/> -->
	</div>
</template>

<script>
import {
	BButton, BModal, VBModal, BAlert,
	BCard, BRow, BCol, BFormInput, BTable, BTr, BTh, BMedia, BAvatar, BLink,
	BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, watch, computed } from '@vue/composition-api'
import { numberFormat, formatDateTime } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import DailyVipFilters from './DailyVipFilters.vue'

export default {
	components: {
		DailyVipFilters,
		// AddBonusModal: () => import('./AddBonusModal.vue'),
		BButton,
		BFormGroup,
		BModal,
		BCard,
		BRow,
		BCol,
		BFormInput,
		BTable,
		BTr,
		BTh,
		BMedia,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
        BAlert,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	data() {
		return {
			roleId: null,
			content: null,
		}
	},
	methods: {
		showAddBonusModal() {
			this.$bvModal.show('modal-add-bonus')
		},
	},
	setup() {
        const dataDemos = [
            { username: 'usertestthb001', product: 'Club 8', eligible_stake_amount: 1694, vip_date: '04-21-2022 07:00:00', status: 2, currency: 'VND', created_at: '04-22-2022 11:00:49', updated_at: '04-22-2022 11:01:33' },
        ]
		const dailyVipLists = ref(dataDemos)
		const perPage = ref(20)
		const totalDailyVip = ref(0)
		const currentPage = ref(1)
		const usernameFilter = ref(null)
		const productFilter = ref(null)
        const productOptions = ref([])
		const statusFilter = ref(null)
		const currencyFilter = ref(null)
        const currencyOptions = ref([])
		const fromdateFilter = ref(null)
		const todateFilter = ref(null)
		const refDailyVipTable = ref(null)
		const tableColumns = [
			{ key: 'index', label: '#' },
			{ key: 'username', label: 'Login name' },
			{ key: 'product', label: 'Product' },
			{ key: 'eligible_stake_amount', label: 'Eligible stake amount' },
            { key: 'vip_date', label: 'Vip date' },
            { key: 'status', label: 'Status' },
            { key: 'currency', label: 'Currency' },
			{ key: 'created_at', label: 'Created at' },
			{ key: 'updated_at', label: 'Updated at' },
		]
		const dataMeta = computed(() => {
			const localItemsCount = refDailyVipTable.value ? refDailyVipTable.value.localItems.length : 0
			return {
				from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
				to: perPage.value * (currentPage.value - 1) + localItemsCount,
				of: totalDailyVip.value,
			}
		})
		const refetchData = () => {
			refDailyVipTable.value.refresh()
		}

		watch([currentPage, perPage, usernameFilter, productFilter, statusFilter, fromdateFilter, todateFilter, currencyFilter], () => {
			refetchData()
		})

		const statusOptions = [
			{ label: 'Pending', value: 1 },
			{ label: 'Complete', value: 2 },
		]

		const resolveStatus = status => {
			if (status == 1) return { label: 'Pending', variant: 'primary' }
			if (status == 2) return { label: 'Complete', variant: 'success' }
			return { label: 'None', variant: 'secondary' }
		}
		return {
			tableColumns,
			perPage,
			currentPage,
			totalDailyVip,
			dataMeta,
			refDailyVipTable,

			dailyVipLists,
			refetchData,

			usernameFilter,
			productFilter,
			statusFilter,
			productOptions,
			fromdateFilter,
			todateFilter,
            currencyFilter,
            currencyOptions,
			resolveStatus,
			statusOptions,
			formatDateTime,
            numberFormat,
		}
	},
}
</script>
<style>
	.b-table-sticky-column {
		right: 0!important;
	}
	td.b-table-sticky-column {
		background-color: inherit;
	}
</style>